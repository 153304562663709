import { useState, TextField, useRef, useEffect, _, isDark } from "V3";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import StaticDateRangePicker from "@material-ui/lab/StaticDateRangePicker";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import moment from "moment";
import "./DataPickerV2.scss";

let readyToUseValues = {};
const updateReadyToUseDateValues = (restrict, allowAllTimes) => {
    const newDates = {
        today: { label: "Today", val: [moment().toISOString(), moment().toISOString()] },
        yesterday: {
            label: "Yesterday",
            val: [moment().subtract(1, "days").toISOString(), moment().subtract(1, "days").toISOString()],
        },
        last7Days: { label: "Last 7 Days", val: [moment().subtract(7, "days").toISOString(), moment().toISOString()] },
        thisMonth: { label: "This Month", val: [moment().startOf("month").toISOString(), moment().toISOString()] },
        lastMonth: {
            label: "Last Month",
            val: [
                moment().subtract(1, "months").startOf("month").toISOString(),
                moment().subtract(1, "months").endOf("month").toISOString(),
            ],
        },
        last30Days: {
            label: "Last 30 Days",
            val: [moment().subtract(30, "days").toISOString(), moment().toISOString()],
        },
    };

    if (!restrict) {
        newDates.last60Days = {
            label: "Last 60 Days",
            val: [moment().subtract(60, "days").toISOString(), moment().toISOString()],
        };
        newDates.last90Days = {
            label: "Last 90 Days",
            val: [moment().subtract(90, "days").toISOString(), moment().toISOString()],
        };
    }

    if (allowAllTimes) {
        newDates.sixMonths = {
            label: "Last 6 Months",
            val: [moment().subtract(6, "months").toISOString(), moment().toISOString()],
        };
        newDates.oneYear = {
            label: "Last 1 Year",
            val: [moment().subtract(1, "years").toISOString(), moment().toISOString()],
        };
        newDates.allTimes = {
            label: "All Times",
            val: [moment("2021-01-01").toISOString(), moment().toISOString()],
        };
    }

    readyToUseValues = newDates;
};

const PickerToolbar = ({ updateStates, restrictLongerRanges, allowAllTimes, setIsAllTimes, ...props }) => (
    <div className="DatePickerV2_pickerToolbar">
        {Object.entries(readyToUseValues).map(([key, item]) => {
            return (
                <div
                    className={"DatePickerV2_readyToUseDateButtons" + (!isDark ? " lightMode" : "")}
                    key={key}
                    onClick={() => {
                        if (allowAllTimes && item.label === "All Times") {
                            setIsAllTimes(true);
                        } else {
                            if (setIsAllTimes) {
                                setIsAllTimes(false);
                            }
                        }
                        updateStates(item.val[0], item.val[1], item.label);
                    }}
                >
                    {item.label}
                </div>
            );
        })}
    </div>
);


const Picker = ({ state, name, updateStates, isMinDate = true, ...props }) => {
    const minDate = moment().subtract(3, 'months').startOf('month');

    return (
        <div className="DatePickerV2_StaticPickerZone">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDateRangePicker
                    displayStaticWrapperAs="desktop"
                    allowSameDateSelection
                    defaultCalendarMonth={moment().subtract(1, "months")}
                    disableFuture
                    closeOnSelect
                    minDate={isMinDate ? minDate : undefined}
                    value={state && state[name] ? state[name] : [null, null]}
                    onChange={(newValue) => {
                        if (moment(state[name][1]).format("YYYY-MM-DD") === moment(newValue[1]).format("YYYY-MM-DD")) {
                            updateStates(newValue[0], null);
                        } else {
                            updateStates(newValue[0], newValue[1]);
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export const DatePickerV2 = ({ variant = "outlined", name, onChange, state, submittedState, restrictLongerRanges = false, allowAllTimes = false, setIsAllTimes, isMinDate = true, ...props }) => {
    const [textFieldText, setTextFieldText] = useState({ textField: " " });
    const [isFocused, setIsFocused] = useState();
    const textFieldRef = useRef();
    const timeoutRef = useRef();

    const updateStates = (startDate, endDate, viewText) => {
        if (!onChange) return;

        onChange([startDate, endDate], name, textFieldText);

        if (startDate && endDate) textFieldRef.current.blur();
    };

    /**
     * Update readyToUseDateValues for each 1 hours
     */
    const updateDates = () => {
        updateReadyToUseDateValues(restrictLongerRanges, allowAllTimes);
        timeoutRef.current = setTimeout(() => updateDates(), 60 * 60 * 1000);
    };
    useEffect(() => updateDates(), [restrictLongerRanges, allowAllTimes]);

    /**
     * Redefine TextFied's text if any selected date changes
     */
    const reDefineTextField = () => {
        let startDate = null,
            endDate = null,
            newText;
        if (state && state[name]) {
            startDate = state[name][0] || null;
            endDate = state[name][1] || null;
        }

        if (!startDate && !endDate) {
            newText = "Select a Date Range";
        } else {
            const startDateStr = startDate ? moment(startDate).format("YYYY-MM-DD") : "...";
            const endDateStr = endDate ? moment(endDate).format("YYYY-MM-DD") : "...";

            const isItAReadyToUseDate = (() => {
                let label;
                _.forEach(readyToUseValues, (item) => {
                    const d1 = moment(item.val[0]).format("YYYY-MM-DD");
                    const d2 = moment(item.val[1]).format("YYYY-MM-DD");
                    if (d1 === startDateStr && d2 === endDateStr) {
                        label = item.label;
                        return false;
                    }
                });
                if (label === 'All Times' && allowAllTimes) {
                    setIsAllTimes(true)
                }
                return label;
            })();

            if (isItAReadyToUseDate) newText = isItAReadyToUseDate;
            else newText = `From ${startDateStr} to ${endDateStr}`;
        }

        setTextFieldText({ textField: newText });
    };
    useEffect(() => reDefineTextField(), [state ? state[name] : false]);

    /**
     * Control Blur session
     * If endDate is empty, delete startDate as well.
     */
    useEffect(() => {
        // if (!isFocused && state && state[name] && !state[name][1]) onChange([null, null], name);
        if (!isFocused && state && state[name] && (!state[name][0] || !state[name][1]))
            onChange([submittedState[name][0], submittedState[name][1]], name);
    }, [isFocused]);

    /**
     * Merge Local Props
     */
    const localProps = {
        textFieldText,
        setTextFieldText,
        isFocused,
        setIsFocused,
        onChange,
        name,
        textFieldRef,
        updateStates,
        state,
    };

    /**
     * Return
     */
    return (
        <div className="DatePickerV2_container">
            <TextField
                fullWidth
                size="small"
                label="Date Range"
                readOnly
                state={textFieldText}
                name="textField"
                onFocus={() => {
                    setIsFocused(true);
                }}
                onBlur={() => setIsFocused(false)}
                inputRef={textFieldRef}
                error={state && state[name] && state[name][0] && state[name][1] ? false : true}
            />

            {!isFocused ? null : (
                <div
                    className={"DatePickerV2_extendedModul" + (!isDark ? " lightMode" : "")}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <PickerToolbar {...localProps} restrictLongerRanges={restrictLongerRanges} setIsAllTimes={setIsAllTimes} allowAllTimes={allowAllTimes} />
                    <Picker {...localProps} isMinDate={isMinDate} />
                </div>
            )}
        </div>
    );
};
