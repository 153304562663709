import { React, useState, useEffect } from "V3";
import { Select as OriginalSelect, InputLabel, MenuItem, FormControl, IconButton } from "@material-ui/core"; // Import additional components
import ClearIcon from '@material-ui/icons/Clear'

export const Select = ({
    state,
    onChange,
    options = [],
    autoWidth = false,
    size = "small",
    sx = { width: "100%" },
    name = "UnnamedField",
    label = "Unnamed Field",
    multiple = false,
    required = false,
    isClearable = false,
    getItemValue = (option) => option,
    getItemLabel = (option) => option,
    ...props
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!open) document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:scroll;");
    }, [open]);

    const handleChange = (e) => {
        const value = e?.target?.value || "";
        const finalValue = typeof value === "string" ? value.split(",") : value;
        onChange(finalValue, name);
    };

    const handleClear = () => {
        onChange(multiple ? [] : "", name);
    };

    /**
     * Return
     */
    return (
        <FormControl sx={{ ...{ minWidth: 120 }, ...sx }} size={size}>
            <InputLabel id={name} required={required}>
                {label}
            </InputLabel>
            <OriginalSelect
                labelId={name}
                id={name}
                open={open}
                displayEmpty
                onClose={() => {
                    setOpen(false);
                    setTimeout(() => document.activeElement.blur(), 0);
                }}
                onOpen={() => setOpen(true)}
                value={state && state[name] ? state[name] : []}
                label={label}
                size={size}
                onChange={handleChange}
                autoWidth={autoWidth}
                required={required}
                multiple={multiple}
                endAdornment={
                    isClearable && state[name] ? (
                        <IconButton style={{ marginRight: "13px" }} onClick={handleClear}>
                            <ClearIcon style={{ width: "20px", height: "20px" }} />
                        </IconButton>
                    ) : null
                }
            >
                {!options
                    ? null
                    : options.map((option) => {
                        const value = getItemValue(option);
                        const label = getItemLabel(option);

                        return (
                            <MenuItem
                                key={label}
                                value={value}
                                style={
                                    state && state[name] && state[name].indexOf(option) > -1
                                        ? { fontWeight: 700 }
                                        : { fontWeight: 400 }
                                }
                            >
                                {label}
                            </MenuItem>
                        );
                    })}
            </OriginalSelect>
        </FormControl>
    );
};
