import { React, useRef } from "V3";
import OriginalTextField from "@material-ui/core/TextField";

export const V3TextField = ({
    fullWidth = true,
    name = "unnamedTextField",
    variant = "outlined",
    value,
    onChange,
    size = "small",
    ...props
}) => {
    const textField = useRef();

    /**
     * Return
     */
    return (
        <OriginalTextField
            id={name}
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value, name)}
            variant={variant}
            inputRef={textField}
            size={size}
            fullWidth={fullWidth}
            autoComplete="new"
            {...props}
        />
    );
};
